import React from "react";
import closeIcon from "@images/shapes/close-1-1.png";
import NavLinksInner from "@components/header/nav-links-inner";
import logo from "@images/Logo-itinerate-v1.png";

const MobileNavInner = () => {
  return (
    <div className="side-menu__block">
      <div className="side-menu__block-overlay custom-cursor__overlay">
        <div className="cursor"></div>
        <div className="cursor-follower"></div>
      </div>
      <div className="side-menu__block-inner ">
          <div className="side-menu__top align-content-center">
              <a href="/">
                  <img src={logo} width="256" alt=""/>
              </a>
          <a href="#" className="side-menu__toggler side-menu__close-btn">
            <img src={closeIcon} alt="" />
          </a>
        </div>

        <nav className="mobile-nav__container">
          <NavLinksInner />
        </nav>
        <div className="side-menu__sep"></div>
      </div>
    </div>
  );
};

export default MobileNavInner;
