import React from "react";
import { Link } from "gatsby";
const NavLinksInner = () => {
  return (
    <ul className="main-nav__navigation-box one-page-scroll-menu">
      <li>
        <Link to="/">
          Home
        </Link>
      </li>
      <li>
        <Link to="/#features">
          Features
        </Link>
      </li>
      <li>
        <Link to="/#testimonials">
          Reviews
        </Link>
      </li>
      <li>
        <Link to="/#screens">
          Screenshots
        </Link>
      </li>
    <li>
        <Link to="/#developer">
            Developer
        </Link>
    </li>
    </ul>
  );
};

export default NavLinksInner;
